<template>
  <div>
    <vs-col>
      <vs-row vs-type="flex" vs-w="12" vs-sm="12" vs-xs="12">
        <h1 class="primary font-bold">Notice Board</h1>
      </vs-row>

      <div class="mt-6">
        <p>Fresh Announcements</p>
        <vs-row
          v-for="item in noticeBoardItems"
          :key="item.id"
          vs-w="6"
          class="mt-5 w-full xl:w-2/5"
        >
          <vs-card class="p-5">
            <div class="flex">
              <img
                :src="
                  item.data.author1.data.image.url != undefined
                    ? item.data.author1.data.image.url
                    : a
                "
                class="rounded-full h-20 w-20 shadow"
                style="height: 40px; width: 40px; border-radius: 10px"
              />
              <div class="flex flex-col ml-5">
                <p class="text-base font-bold break-all">
                  {{ item.data.author1.data.name[0].text }}
                </p>
                <p>{{ moment(item.first_publication_date) }}</p>
              </div>
            </div>
            <div class="mt-3">
              <p
                class="font-bold text-lg break-word"
                style="margin-bottom: 24px"
              >
                {{ item.data.title[0].text }}
              </p>
              <p
                class="text-sm break-word"
                v-html="showDescription(item.data.description)"
              ></p>
            </div>
            <div class="mt-3">
              <img
                :src="item.data.image.url"
                style="
                  height: 250px;
                  width: 100%;
                  border-radius: 5px;
                  object-fit: contain;
                "
              />
            </div>
          </vs-card>
        </vs-row>
      </div>
    </vs-col>
  </div>
</template>


<script>
import { mapActions } from "vuex";
import inviteUser from "../components/others/inviteUser";
import moment from "moment";
import PrismicDom from "prismic-dom";

export default {
  components: {
    inviteUser,
  },
  data() {
    return {
      noticeBoardItems: [],
    };
  },
  methods: {
    ...mapActions("basic", ["fetchNoticeBoardItems"]),
    moment(date) {
      return moment(date).format("DD MMM YYYY HH:mm a");
    },
    showDescription(description) {
      function linkResolver(doc) {
        // Pretty URLs for known types
        if (doc.type === "blog") return "/post/" + doc.uid;
        if (doc.type === "page") return "/" + doc.uid;
        // Fallback for other types, in case new custom types get created
        return "/doc/" + doc.id;
      }
      return PrismicDom.RichText.asHtml(description, linkResolver);
    },
    getData() {
      this.$vs.loading();
      this.fetchNoticeBoardItems()
        .then((res) => {
          this.noticeBoardItems = res.data.data.sort(
            (a, b) =>
              new Date(b.first_publication_date) -
              new Date(a.first_publication_date)
          );
          // console.log("res", this.noticeBoardItems);
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
        });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style scoped>
.heading {
  font-size: large;
  font-weight: 600;
  color: #000;
}
.count {
  font-size: x-large;
  font-weight: 700;
  text-align: center;
}
</style>
